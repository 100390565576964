import {ChangeDetectorRef, Directive, EventEmitter, HostListener, Input, OnDestroy, Output} from '@angular/core'
import {mergeMap, Subject, timer} from 'rxjs'
import {takeUntil} from 'rxjs/operators'

@Directive({
  selector: '[cftAnimationState]',
  exportAs: 'cftAnimationState',
})
export class AnimationStateDirective implements OnDestroy {
  @Input() eventType = 'click' as const
  @Input() disableAnimationDelay = false
  @Output() animate = new EventEmitter<'show' | 'idle' | 'none'>()

  private readonly unsubscribe$ = new Subject<void>()
  private _animation: 'show' | 'idle' | 'none' = 'none'

  get animation(): 'show' | 'idle' | 'none' {
    return this._animation
  }

  private set animation(value) {
    this._animation = value
    this.animate.emit(value)
  }

  constructor(private readonly cdr: ChangeDetectorRef) {}

  @HostListener('click', ['$event'])
  onMouseDown(e: MouseEvent) {
    if (this.eventType !== 'click') return
    if (this.disableAnimationDelay) return
    this.click(e)
  }

  private click(e: MouseEvent) {
    if (this.animation !== 'none') return

    e.preventDefault()
    e.stopPropagation()

    this.animation = 'show'
    // show animation, wait, hide animation, wait, resume.
    // this is so that the animation can finish before resuming propagation e.g. navigating away from the page
    timer(450)
      .pipe(
        mergeMap(() => {
          this.animation = 'idle'
          this.cdr.markForCheck()
          return timer(150)
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        //resume propagation
        const element = e.target as HTMLElement
        element?.click()
        this.animation = 'none'
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
